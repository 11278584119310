import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Footer from './Footer';
import { signInWithEmailAndPassword, getAuth, onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase'; // Ensure correct imports
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const db = getFirestore();
  async function getUserCredits(userid) {
    const userRef = doc(db, 'userProfiles', userid);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      localStorage.setItem('credits', data.credits)
      localStorage.setItem('type', data.type)

    } else {
      return null;
    }
  }


  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/'); // Redirect to home or another page
      }
    });
  }, [navigate]);
  const onLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userid = user.uid;
      localStorage.setItem('uid', userid)

      getUserCredits(userid).then(() => {
        
        Swal.fire({
          icon: 'success',
          title: 'Sign In Successfully',
          timer: 1500,
          customClass: {
            confirmButton: 'my-confirm-button', // Add your custom class here
        }
        }).then(() => {
          window.location.href = '/';
        })
      })

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Email or password are wrong!',
        customClass: {
          confirmButton: 'my-confirm-button', // Add your custom class here
      }
      });
    }
  };


  return (
    <>
      <Helmet>
                    <title>BadSkip | Sign In </title>
                </Helmet>  
      <section className="homeFourth formSection account signin">
        <Container fluid>
          <Row>

            <Col lg={6} md={6} sm={12}>
              <div className="formDiv">
                <h2>Sign In</h2>

                <form method="post" onSubmit={(e) => onLogin(e)}>
                  <div>
                    <input className="form-control" type='text' name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email Address" />
                  </div>
                  <div>
                    <input className="form-control"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type='password' placeholder="Password" />
                  </div>
                  <div className='haveAccount forget'>
                    <p>Forgot your password?</p><Link to="/ForgetPassword">  Reset it now.</Link>
                  </div>
                  <div>
                    <button type="submit">Sign In</button>
                  </div>
                  <div className='haveAccount'>
                    <p>Don't have an account?</p><Link to="/Register"> Register now!</Link>
                  </div>
                  <div className='border'></div>
                  <div className='haveAccount'>

                    <p>Are you a call center?</p><Link to="/CallCenterRegistration"> Click here to register!</Link>
                  </div>
                </form>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>

              <img src="images/Clippath.png" alt="account" />

            </Col>

          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default SignIn