import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Footer from './Footer';
import axios from 'axios';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import the CSS file for styling
import { Helmet } from 'react-helmet';

function Register() {
    const navigate = useNavigate();
    const auth = getAuth();
    const apiKey = process.env.REACT_APP_API_KEY;

    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        confirm_password: "",
        phone: "",
        recive: false
    });

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                navigate('/'); // Redirect to home or another page
            }
        });

        return () => unsubscribe();
    }, [navigate, auth]);

    const onInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUser({
            ...user,
            [name]: type === 'checkbox' ? checked : value
        });
        };

    const handlePhoneChange = (value) => {
        setUser({ ...user, phone: value });
    };

    const urlParams = new URLSearchParams(window.location.search);
    const referrer = urlParams.get('username') || "none";

    const onSubmit = async (e) => {
        e.preventDefault();
        if (user.password !== user.confirm_password) {
            Swal.fire({
                icon: 'error',
                title: 'Passwords do not match',
                text: 'Please check and try again.',
                customClass: {
                    confirmButton: 'my-confirm-button',
                }
            });
            return;
        }
        try {
            const payload = {
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
                password: user.password,
                phoneNumber: user.phone,
                userName: user.username,
                referrer: referrer,
                consentToMarketingCommunications: user.consentToMarketingCommunications

            };

            const response = await axios.post("https://api.v1.badskip.com/user/register", payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey
                }
            });

            if (response.data) {
                Swal.fire({
                    icon: 'success',
                    title: `Registered Succesfully`,
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                        confirmButton: 'my-confirm-button', // Add your custom class here
                    }
                });
                localStorage.setItem('uid', response.data.uid);
                localStorage.setItem('credits', response.data.credits);
                try {
                    await signInWithEmailAndPassword(auth, user.email, user.password);
                } catch (signInError) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Sign-in failed',
                        text: signInError.message,
                        customClass: {
                            confirmButton: 'my-confirm-button',
                        }
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Registration failed',
                    text: 'Please try again.',
                    customClass: {
                        confirmButton: 'my-confirm-button', // Add your custom class here
                    }
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Registration failed',
                text: error.response.data.message,
                customClass: {
                    confirmButton: 'my-confirm-button', // Add your custom class here
                }
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>BadSkip | Register </title>
            </Helmet>
            <section className="homeFourth formSection account">
                <h2>Register an Account</h2>
                <Container fluid>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className="formDiv">
                                <form method="post" onSubmit={(e) => onSubmit(e)}>
                                    <div>
                                        <input className="form-control"
                                            name="first_name"
                                            value={user.first_name}
                                            onChange={(e) => onInputChange(e)}
                                            type='text' placeholder="First Name" />
                                    </div>
                                    <div>
                                        <input className="form-control"
                                            name="last_name"
                                            value={user.last_name}
                                            onChange={(e) => onInputChange(e)}
                                            type='text' placeholder="Last Name" />
                                    </div>
                                    <div>
                                        <input className="form-control" name="username"
                                            value={user.username}
                                            onChange={(e) => onInputChange(e)}
                                            type='text' placeholder="Username" />
                                    </div>
                                    <div>
                                        <input className="form-control" name="email"
                                            value={user.email}
                                            onChange={(e) => onInputChange(e)}
                                            type='email' placeholder="Email" />
                                    </div>
                                    <div>
                                        <PhoneInput
                                        country={"us"}
                                            value={user.phone}
                                            onChange={handlePhoneChange}
                                            inputClass="form-control"
                                            placeholder='phone'
                                            containerStyle={{ marginBottom: '15px' }} // Optional styling
                                        />
                                    </div>
                                    <div>
                                        <input className="form-control" name="password"
                                            value={user.password}
                                            onChange={(e) => onInputChange(e)} type='password' placeholder="Password " />
                                    </div>
                                    <div>
                                        <input className="form-control" name="confirm_password"
                                            value={user.confirm_password}
                                            onChange={(e) => onInputChange(e)} type='password' placeholder="Confirm Password " />
                                    </div>
                                    <div className='checkbox'>
                                        <input name="recive" type='checkbox'  value={user.recive}
                                            onChange={(e) => onInputChange(e)} />
                                        <p>I consent to receive texts, and emails from <span>BadSkip</span></p>
                                    </div>
                                    <div>
                                        <button type="submit">Sign Up</button>
                                    </div>
                                    <div className='haveAccount'>
                                        <p>Already have an account?</p><Link to="/SignIn"> Login now!</Link>
                                    </div>
                                </form>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <img src="images/Clippath.png" alt="account" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default Register;
